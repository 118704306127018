import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import {
  AccountInfo,
  ActivePlayers,
  AgreeTree,
  AuthPage,
  BlockedPlayers,
  CommissionPlan,
  Configuration,
  Dashboard,
  DeletedUsers,
  Filters,
  MessagesChat,
  NetworkUsers,
  Permissions,
  ReportsActivities,
  ReportsGeneral,
  ReportsRefferal,
  ReportsSubAgent,
  ReportsTransaction,
  SportsBet,
  Wallet,
} from "pages";
import { useTypeSelector } from "hooks";

const Navigation = () => {
  const { role } = useTypeSelector((data) => data.accountInfoReducer);
  return (
    <Router>
      <Routes>
        {role === "auth" ? (
          <>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path={"*"} element={<Dashboard />} />
            <Route path={"/dashboard"} element={<Dashboard />} />
            <Route path={"/network/customers"} element={<NetworkUsers />} />
            <Route
              path={"/network/deletedAffilates"}
              element={<DeletedUsers />}
            />
            <Route path={"/agentTree"} element={<AgreeTree />} />
            <Route path={"/players/players"} element={<ActivePlayers />} />
            <Route
              path={"/players/blockedPlayers"}
              element={<BlockedPlayers />}
            />
            <Route path={"/filters"} element={<Filters />} />
            <Route path={"/configuration"} element={<Configuration />} />
            <Route
              path={"/configuration/commissionPlan"}
              element={<CommissionPlan />}
            />
            <Route
              path={"/configuration/permissions"}
              element={<Permissions />}
            />
            <Route path={"/wallet"} element={<Wallet />} />
            <Route path={"/messages/chats"} element={<MessagesChat />} />
            <Route
              path={"/account/:accountId/:page"}
              element={<AccountInfo />}
            />
            <Route
              path={"/account/:accountId/:page/:logsPage"}
              element={<AccountInfo />}
            />
            <Route
              path={"/reports/productsReport/:reportsPage/"}
              element={<ReportsGeneral />}
            />
            <Route
              path={"/reports/productsReport/products/:productId"}
              element={<ReportsGeneral />}
            />
            <Route
              path={"/reports/transactionReport/:reportsPage/"}
              element={<ReportsTransaction />}
            />
            <Route path={"/reports/betsReport"} element={<SportsBet />} />
            <Route
              path={"/reports/subAgentsStatistics"}
              element={<ReportsSubAgent />}
            />
            <Route
              path={"/reports/referralReport"}
              element={<ReportsRefferal />}
            />
            <Route
              path={"/reports/activityReportByAffiliates"}
              element={<ReportsActivities />}
            />
          </>
        ) : (
          <>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path={"/"} element={<AuthPage />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

export { Navigation };
